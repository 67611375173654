import {connect, ConnectedProps} from "react-redux";
import {setShowCookieOverlay} from "../../stores/cookies/cookieSlice";
import * as React from "react";
import {FC, useEffect} from "react";

const mapState = ({cookie}) => ({
    cookie
})
const mapDispatch = {
    setShowCookieOverlay
}
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>


const CookieGtag: FC<PropsFromRedux & any> = ({cookie}) => {

    useEffect(() => {
        console.log('yes')
        window.scrollTo(0, 0);
    }, [window.location.pathname])

    useEffect(() => {

        if (cookie.FunctionalCookies) {
            // @ts-ignore
            window.dataLayer.push({'event': 'fs.functionalCookiesTRUE'})
        }

        if (cookie.TargetingCookies) {
            // @ts-ignore
            window.dataLayer.push({'event': 'fs.targetingCookiesTRUE'})
        }


        // @ts-ignore
        window.dataLayer.push({'event': 'fs.performanceCookiesTRUE'})


        //please don't attack me for not using gatsby-google-tag, blame 9wolF
        window.eval(`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                
                gtag('consent', 'default', {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied'
});

    
                gtag('js', new Date());
    
                gtag('config', 'AW-10887418845');
                gtag('js', new Date());
    
                gtag('config', 'G-LL0SBC6VCK');
                gtag('js', new Date());
                gtag('config', 'UA-214167216-1');
            `);

        if (cookie.PerformanceCookies) {
            window.eval(`
            gtag('consent', 'update', {
  'ad_storage': 'granted',
  'ad_user_data': 'granted',
  'ad_personalization': 'granted',
  'analytics_storage': 'granted'
});
            `);
        }

        const googleScripts = [
            'https://www.googletagmanager.com/gtag/js?id=AW-10887418845',
            'https://www.googletagmanager.com/gtag/js?id=G-LL0SBC6VCK',
            'https://www.googletagmanager.com/gtag/js?id=UA-214167216-1'
        ];

        googleScripts.forEach(script => {
            const el = document.createElement('script');
            el.src = script;
            document.head.append(el);
        });

        // facebook
        window.eval(`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '500648488556999');
                fbq('track', 'PageView');
            `);


    }, [cookie.PerformanceCookies])

    return (
        <> </>
    );
}


export default connector(CookieGtag);